@import "./../main";

.card-title {
  text-align: center;
  font-size: 100%;
  font-weight: 700;
  margin-bottom: 3.5rem;
}

.card-body {
  font-weight: 600;
  min-height: 35rem;
  color: $base-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .card-body {
    font-size: 12px;
    min-height: 28rem;
  }
}

@media (max-width: 768px) {
  .card-body {
    font-size: 10px;
    min-height: 20rem;
  }
}

@media (max-width: 425px) {
  .card-body {
    font-size: 10px;
    min-height: 15rem;
  }
}
