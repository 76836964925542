@import "./../../main";

#footer {
  position: absolute;
  bottom: 0;
  background: $light-color;
  background: linear-gradient(90deg, $light-color 100% rgba(3, 24, 66, 1) 0%);
}

.content-wrap {
  height: 6rem;
  padding-bottom: 2.5rem;
}

.footer-text {
  height: 2rem;
  font-weight: 600;
  color: $base-color;
}

@media (max-width: 768px) {
  .content-wrap {
    height: 8rem;
  }
  .footer-text {
    height: 4rem;
  }
}

@media (max-width: 425px) {
  .content-wrap {
    height: 12rem;
  }
  .footer-text {
    height: 6rem;
  }
}
