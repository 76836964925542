@import "./../main";

.form-btn {
  box-shadow: inset 0px 0px 15px 3px #23395e;
  background: linear-gradient(to bottom, #2e466e 5%, #415989 100%);
  background-color: #2e466e;
  border-radius: 17px;
  border: 1px solid #1f2f47;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  padding: 6px 13px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
  transition: all 0.25s ease-out;
}
.form-btn:hover {
  background: linear-gradient(to bottom, #bdc1c9 5%, #6984b9 100%);
  background-color: #415989;
}
.form-btn:active {
  position: relative;
  top: 1px;
}
