@import "./../main";

.list-text {
  font-weight: 700;
}

@media (max-width: 768px) {
  .list-text {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .list-text {
    font-size: 10px;
  }
} ;
