@import "./../main";

.services-bg {
  background-image: url("./../../assets/img/BG/services.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 12rem;
}
