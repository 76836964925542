//Fonts
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;300;400;700;900&family=Lato:wght@100;300;400;700;900&display=swap");

//Variables
$base-color: #042464;
$base-color-light: #047aba;
$base-color-dark: #031842;
$base-color-ultralight: #04b3f3;
$warning-color: #d42f1a;
$warning-background-color: #fee7e9;
$text-color: #042464;
$input-color: #64779e;
$gray-light: #c1cbda;
$light-color: #ffffff;
$input-active-color: #649fd6;
$overlay-color: #ffffff85;
$background-color: #222222;
$base-font: "Lato", sans-serif;
$alt-font: "Encode Sans", sans-serif;

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
//CSS reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: $base-font;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: grid;
  overflow-x: hidden;
  color: $text-color;
  font-size: 1.6rem;
  background-color: $background-color;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}
// FIN RESETS

.gmp {
  box-sizing: border-box;
  padding: 1rem;
  background: $light-color;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(43, 67, 113, 0.55) 100%
  );
  color: $base-color;
  font-weight: bold;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
