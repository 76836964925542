@import "./../main";

.companyName-container {
  background: $light-color;
  background: linear-gradient(
    90deg,
    $light-color,
    $light-color 50%,
    rgba(3, 24, 66, 1) 100%
  );
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#CompanyName {
  font-size: 35px;
  font-weight: 700;
  color: $base-color;
}

.home-bg {
  background-image: url("./../../assets/img/BG/home2.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-bottom: 12rem;
}
