@import "./../../main";

.nav-item {
  font-weight: 700;
  color: $base-color;
  transition: all 0.25s ease-in-out;
  &:hover {
    transition: all 0.25s ease-in-out;
    color: $base-color-light;
  }
}
