@import "./../main";

.clients-bg {
  background-image: url("./../../assets/img/BG/clients.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 12rem;
}
