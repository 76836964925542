@import "./../main";

.img {
  border-radius: 15px;
}

.final-text-container {
  background-color: $light-color;
  height: 100%;
}

.blank-container {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
  background-color: white;
  width: 100%;
}
