@import "./../main";

.card-title {
  font-weight: 700;
  font-size: 150%;
}

@media (max-width: 768px) {
  .card-img-top {
    width: 60%;
  }
}
