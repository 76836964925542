@import "./../main";

.sale-bg {
  background-image: url("./../../assets/img/BG/sale.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 12rem;
  min-height: 87vh;
}
